declare const DEVISE_FORM_ERRORS: { [key: string]: string[] }
declare const DEVISE_FORM_DATA: { email: string; agbs_read: boolean; remember_me: boolean }

type DFD = typeof DEVISE_FORM_DATA

export function deviseFormData<K extends keyof DFD>(key: K, fallback: DFD[K]): DFD[K] {
  return DEVISE_FORM_DATA[key] || fallback
}

export function deviseFormErrors(): { [key: string]: string } {
  const ret: { [key: string]: string } = {}
  Object.keys(DEVISE_FORM_ERRORS).forEach(
    key => (ret['user[' + key + ']'] = DEVISE_FORM_ERRORS[key].join(', ')),
  )
  return ret
}
