import * as React from 'react'
import { color, fontSize, space } from '@dropscan/ds/tokens'

import { InputProps } from '@dropscan/forms'
import CheckcircleEmpty from '@dropscan/icons/ui-checkcircle-empty.svg'
import CheckcircleChecked from '@dropscan/icons/ui-checkcircle-checked.svg'
import CheckboxEmpty from '@dropscan/icons/ui-checkbox-empty.svg'
import CheckboxChecked from '@dropscan/icons/ui-checkbox-checked.svg'

import styled from '../styled'
import Label, { Props as LabelProps } from './Label'

type LabelAttributes = Omit<React.HTMLAttributes<HTMLLabelElement>, 'onBlur' | 'onClick' | 'value'>

interface Props extends Partial<InputProps<boolean>>, LabelProps, LabelAttributes {
  labelText?: React.ReactNode
  onClick?: (e: React.KeyboardEvent | React.MouseEvent) => void
  circle?: boolean
}

/**
 * A themed checkbox input that also handles keyboard events (hit "return" to toggle).
 */
export function Checkbox({
  value,
  onChangeValue,
  labelText,
  validationState,
  error,
  name,
  onClick,
  circle,
  ...inputProps
}: Props) {
  const [inBrowser, setInBrowser] = React.useState(false)
  React.useEffect(() => {
    setInBrowser(true)
  }, [])
  const checked = (inBrowser && value) || false
  return (
    <>
      <Label
        role="checkbox"
        css={{
          fontSize: fontSize(2),
          marginTop: space(1),
          '& svg': { fontSize: '1.25em' },
        }}
        aria-checked={checked}
        tabIndex={0}
        spacing="compact"
        onClick={(e: React.KeyboardEvent | React.MouseEvent) => {
          if (onClick) {
            onClick(e)
          }
          if (!inputProps.disabled && !e.isPropagationStopped() && onChangeValue) {
            onChangeValue(!value)
            inputProps.onBlur?.()
          }
        }}
        validationState={validationState}
        htmlFor={name}
        {...inputProps}
      >
        {circle ? <CheckboxCircle checked={checked} /> : <CheckboxSquare checked={checked} />}
        <input type="hidden" name={name} value={checked ? '1' : '0'} />
        {labelText && <Spacer />}
        {labelText}
      </Label>
      {validationState === 'error' && <ErrorText>{error}</ErrorText>}
    </>
  )
}

export default Checkbox

const Spacer = styled.div({ width: space(3) })

const CheckboxCircle = ({ checked }: { checked?: boolean }) =>
  checked ? <CheckcircleChecked /> : <CheckcircleEmpty />

const CheckboxSquare = ({ checked }: { checked?: boolean }) =>
  checked ? <CheckboxChecked /> : <CheckboxEmpty />

const ErrorText = styled.span({
  color: color('danger-500'),
  fontSize: fontSize(2),
})
