import React from 'react'

import { useNotifier } from './contexts'

declare const FLASH: { [key: string]: string }

export default function FlashNotificationHandler({
  flashMessages = typeof FLASH === 'object' ? FLASH : {},
}: {
  flashMessages?: typeof FLASH
}) {
  const notify = useNotifier()
  const notified = React.useRef(false)

  React.useEffect(() => {
    if (!notified.current) {
      if (flashMessages.error) {
        notify.now({ priority: 'error', content: flashMessages.error })
      }
      if (flashMessages.alert) {
        notify.now({ priority: 'error', content: flashMessages.alert })
      }
      if (FLASH.notice) {
        notify.now({ priority: 'success', content: flashMessages.notice })
      }
      notified.current = true
    }
  })
  return null
}
