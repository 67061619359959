import * as React from 'react'

import { NotificationListContext, NotifierContext } from './contexts'
import { NotificationOptions, NotificationRenderProps, Notifier, PriorityRank } from './interfaces'

interface State {
  notifications: NotificationRenderProps[]
}

export default class NotificationProvider extends React.Component<React.PropsWithChildren, State> {
  state: State
  private nextId: number
  private notifier: Notifier

  constructor(props: {}) {
    super(props)
    this.nextId = 1
    this.notifier = {
      now: this.addNotification.bind(this),
      after: this.addResultNotification.bind(this) as Notifier['after'],
    }
    this.state = { notifications: [] }
  }

  render() {
    return (
      <NotifierContext.Provider value={this.notifier}>
        <NotificationListContext.Provider value={this.state.notifications}>
          {this.props.children}
        </NotificationListContext.Provider>
      </NotifierContext.Provider>
    )
  }

  private addNotification({
    id = `auto-${this.nextId++}`,
    priority,
    content,
    sticky = false,
    onDismiss,
  }: NotificationOptions) {
    const props: NotificationRenderProps = {
      id,
      sticky,
      priority,
      onDismiss: () => {
        if (onDismiss) {
          onDismiss()
        }
        this.clearNotification(id)
      },
      children: content,
    }
    const notifications = this.state.notifications.slice()
    let i = 0
    let remove = 0
    for (const len = notifications.length; i < len; i++) {
      const other = notifications[i]
      if (other.id === id) {
        remove = 1
        break
      }
      if (PriorityRank[priority] > PriorityRank[other.priority]) {
        break
      }
    }

    notifications.splice(i, remove, props)

    this.setState({ notifications })

    return props.onDismiss
  }

  private clearNotification(removeId: string) {
    this.setState(({ notifications }) => ({
      notifications: notifications.filter(({ id }) => id !== removeId),
    }))
  }

  private addResultNotification<T>(
    promise: Promise<T>,
    messages: { success: React.ReactNode; error?: React.ReactNode | ((error: Error) => string) },
  ): Promise<T> {
    return promise.then(
      result => {
        if (typeof result !== 'string' || result !== 'cancelled') {
          this.addNotification({
            priority: 'success',
            content: messages.success,
          })
        }
        return result
      },
      error => {
        const content =
          typeof messages.error === 'function' ? messages.error(error) : messages.error
        this.addNotification({
          priority: 'error',
          content: content || 'Es ist ein Problem aufgetreten. Bitte versuchen Sie es erneut.',
        })
        return Promise.reject(error)
      },
    )
  }
}
