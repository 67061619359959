import 'initializers/__webpack_public_path__'
import 'initializers/sentry'

import { deviseFormData, deviseFormErrors } from '@dropscan/forms/devise'

import { LoginApp } from 'customers/LoginApp'
import { hydrateRoot } from 'react-dom/client'
import { StrictMode } from 'react'

declare const RECAPTCHA_SITE_KEY: string | null

const initialFormData = {
  'user[email]': deviseFormData('email', ''),
  'user[password]': '',
  'user[remember_me]': deviseFormData('remember_me', false),
  'g-recaptcha-response': '',
}

hydrateRoot(
  document.getElementById('app')!,
  <StrictMode>
    <LoginApp
      recaptchaSiteKey={RECAPTCHA_SITE_KEY}
      initialFormData={initialFormData}
      initialFormErrors={deviseFormErrors()}
    />
  </StrictMode>,
)
